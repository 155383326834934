
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import HeaderUser from "@/components/layouts/HeaderComponents/HeaderUser.vue";
import Login from "@/views/User/Login.vue";
import dayjs from "dayjs";
import JoinModal from "./component/JoinModal.vue";
const UserModule = namespace("user");
const teamModule = namespace("team");

@Component({
  name: "TeamInvited",
  components: { HeaderUser, Login, JoinModal },
  filters: {
    //局部过滤器
  },
})
export default class TeamInvited extends Vue {
  public spin: boolean = false;
  public showJoin: boolean = false; // 是否展示加入企业的弹框
  public joinData: any = {}; // 给加入企业弹框传递的数据
  public inviteCode: string = "";
  public inviteId: string = "";
  public loading: boolean = false;
  public error: boolean = false;
  public teamDetails: any = {};

  @UserModule.State("token") token!: string;
  @UserModule.State("name") userName!: string;
  @UserModule.Action("GET_USERINFO") getUserinfo!: (type?: string) => void;
  @teamModule.State("teamInvitedLoginShow") loginShow!: boolean;
  @teamModule.Mutation("SET_TEAMINVITEDLOGINSHOW") setLoginShow!: (
    value: boolean
  ) => void;

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("$route.query", { deep: true, immediate: true })
  change(value: any) {
    this.inviteCode = value.inviteCode;
    this.inviteId = value.inviteId;
    console.log(this.inviteCode, this.inviteId);
    this.spin = true;
    this.$apis
      .companyTeamQueryInviteInfo({
        inviteCode: this.inviteCode,
        inviteId: this.inviteId,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.teamDetails = res.data;
        }
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.spin = false;
      });
  }
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 登录
  login() {
    this.setLoginShow(true);
  }
  // 关闭企业弹窗做了些什么
  closeJoinModal() {
    this.showJoin = false;
    console.log("🚀 ~  关闭企业弹窗做了些什么l:");
  }

  // 加入
  handleJoin() {
    // return;
    // 测试暂时隐去后面流程
    if (this.token) {
      let messageInfo = `${this.userName},${this.teamDetails.teamName}`;
      messageInfo = encodeURI(messageInfo);
      const header = {
        messageType: "team",
        messageTo: this.teamDetails.userId,
        messageInfo,
      };
      this.$apis
        .companyTeamAddByInviteLink(
          {
            inviteCode: this.inviteCode,
            inviteId: this.inviteId,
          },
          header
        )
        .then(async (res: any) => {
          if (res.code === "200") {
            await this.getUserinfo("userinfo");
            this.$message.success("加入成功");
            this.$router.push({ path: "/star/companyTeam/table" });
          }
        })
        .catch((err: any) => {
          console.log("加入企业团队失败", err);
          if (err.msg == "非该企业成员不能加入") {
            // alert('99')
            setTimeout(() => {
            this.showJoin = true;
              // TODO在移动端不可以自动跳回，因为没有做过移动端适配
              // this.goHome()
            }, 3000);
          }
        });
    } else {
      this.$message.warning("账号未登录,请先登录后加入");
      this.setLoginShow(true);
    }
  }

  // 去到首页
  goHome() {
    this.$router.push({ path: "/star" });
  }

  //创建前钩子函数
  created(): void {
    this.getUserinfo();
    this.joinData.name=this.userName
  }
}
