<template>
  <a-modal
    v-model="showModal"
    :maskClosable="true"
    @cancel="handleCancel"
    :okButtonProps="{ style: { display: oKshow ? 'initial' : 'none' } }"
    title="加入新企业"
    wrapClassName="company-modal-wrap"
    dialogClass="company-modal"
  >
    <div v-if="startPage && !resultPage">
      <div class="mb20">
        <p style="font-weight: 600; font-size: 14px; color: #333">
          通过企业邀请码加入
        </p>
        <p>
          对方邀请您加入企业<span style="font-weight: 700"
            >【{{ companyName }}】</span
          >
        </p>
        <!-- <p>企业邀请码可以向管理员在“成员管理”中点击邀请成员获得</p> -->
      </div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="企业邀请码" prop="code">
          <a-input v-model="form.code" placeholder="请输入企业邀请码" />
        </a-form-model-item>
        <!-- <a-form-model-item label='备注'>
        <a-input
          v-model='form.remarks'
          type='textarea'
          placeholder='请输入'
        />
      </a-form-model-item> -->
      </a-form-model>
    </div>
    <div v-else-if="!startPage && !resultPage">
      <div style="font-weight: 700; font-size: 16px">
        欢迎您加入{{ companyName }}
      </div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item label="发送加入申请理由" prop="remarks">
          <a-input v-model="form.remarks" placeholder="请输入申请理由" />
        </a-form-model-item>
        <!-- <a-form-model-item label='备注'>
        <a-input
          v-model='form.remarks'
          type='textarea'
          placeholder='请输入'
        />
      </a-form-model-item> -->
      </a-form-model>
    </div>
    <div v-else>
      <a-result
        :status="
          state === 2
            ? 'warning'
            : state === 0 || state === 1
            ? 'success'
            : 'warning'
        "
      >
        <template #title>
          <div style="font-weight: 700; font-size: 16px; color: #333">
            {{ resultMesssage }}
          </div>
        </template>
        <template #subTitle>
          <div class="mt10">
            {{
              state === 0 || state === 4
                ? "快来进入平台与团队伙伴开启协作办公之旅吧"
                : "正在等待企业管理员审核…"
            }}
          </div>
        </template>
        <template #extra>
          <a class="mt10 cur_p" href="#" @click="openApp">点击进入平台</a>
          <a-tooltip placement="bottom">
            <a-button class="btn_down" type="primary">下载App</a-button>
            <template #title>
              <img width="120" src="@/assets/images/androids.png" alt="" />
              <img src="" alt="" />
            </template>
          </a-tooltip>
          <!-- <div class="mt10 cur_p">
            已有Dana AI 智能体？<a href="#" @click="openApp">打开</a>
          </div> -->
        </template>
      </a-result>
    </div>
    <!-- <a-checkbox
      :checked="agreeProtocol"
      @change="handleChange"
      class="protocol-wrap"
    >
    我已阅读并同意<span class="protocol"  @click.stop.prevent="agreementShow = true; states = '0'">《用户服务协议》</span>与<span @click.stop.prevent="agreementShow = true; states = '1'" class="privacy">《隐私政策》</span>
    </a-checkbox> -->

    <template slot="footer">
      <div class="btns-wrap" v-if="!resultPage">
        <a-button
          @click="startPage ? handleCancel() : (startPage = true)"
          :block="true"
          class="cancel"
        >
          {{ startPage ? "取消" : "上一步" }}
        </a-button>
        <a-button
          type="primary"
          :block="true"
          :loading="!resultPage && loading"
          @click="startPage ? next() : newSendQuest()"
        >
          {{ startPage ? "下一步" : "提交" }}
        </a-button>
        <!-- <agreement :state="states" :showModal="agreementShow" @cancel="agreementShow = false" /> -->
      </div>
    </template>
  </a-modal>
</template>

<script>
// import { codeAuth } from '@api/enterprise'
// import { showMsg } from '@/utils/util'
// import agreement from '@/views/workbench/agreement.vue'
// import { selectTenant } from '@api/tenantManagement'
import Vue from "vue";
// import { TENANT_ID } from '@/store/mutation-types'
import store from "@/store";
// import AppDown from '@/assets/images/androids.png'
// import { getTenantInfo } from '@/api/tenantManagement'
import { Result } from "ant-design-vue";

export default {
  components: { Result },
  name: "JoinModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // showJoinTimestamp: {
    //   type: number,
    //   default: 0,
    // },

    modalData: {
      type: Object,
      default: null,
    },
    oKshow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.showModal = true;
      }
    },
  },
  data() {
    return {
      state: 0, // 0审核中  1 已成功 4  已成功无需加入
      // AppDown,
      companyName: "",
      resultMesssage: "",
      resultPage: false,
      startPage: true,
      showModal: true,
      agreementShow: false,
      loading: false,
      states: "",
      tenantName: "",
      form: {
        code: "",
        remarks: "",
      },
      rules: {
        code: [{ required: true, message: "请输入邀请码", trigger: "blur" }],
      },
      isLoading: false,
      agreeProtocol: true,
    };
  },
  mounted() {
    if (this.modalData) {
      this.form.remarks = "我是" + this.modalData.name;
    }
    let invitationCode = new URLSearchParams(window.location.search).get(
      "invitationCode"
    );
    this.companyName = decodeURIComponent(new URLSearchParams(window.location.search).get(
      "companyName"
    )) 

    console.log("弹框后获取的企业邀请码:", invitationCode);
    if (invitationCode) {
      this.form.code = invitationCode;
    }
  },
  methods: {
    // 打开app
    openApp() {
      open(location.origin, "_self");
    },
    // 勾选协议
    handleChange({ target: { checked } }) {
      this.agreeProtocol = checked;
    },
    async setTenant(tenantId) {
      const success = "123";
      const result = "123";
      // let { success, result } = await selectTenant({ tenantId })
      console.log("success", success);
      console.log("result", result);
      // if (success) {
      //   Vue.ls.set(TENANT_ID, tenantId, 7 * 24 * 60 * 60 * 1000)
      //   store.commit('SET_TENANT', tenantId)
      //   store.commit('REFRESH_USERINFO', result)
      //   location = "/"
      // }
    },
    async next() {
      if (!this.form.code) {
        this.$message.error("请先输入邀请码");
        return;
      }
      // this.loading = true;
      // const code = "xx";
      // const message = "00";
      // // const { code, message } = await getTenantInfo({ code: this.form.code })
      // this.loading = false;
      // if (code !== 200) {
      //   // this.$message.error(message)
      //   return;
      // }

      this.startPage = false;
      // this.tenantName = message;
    },
    async newSendQuest() {
      this.loading = true;
      const res = await this.$apis.codeAuthApi(this.form);
      console.log("邀请码认证 ~ res:", res);
      this.loading = false;
      this.resultPage = true;
      this.state = res.result.authResult;
      switch (res.result.authResult) {
        case 0:
          this.resultMesssage = `恭喜，您已成功加入${this.companyName}`;
          break;
        case 1:
          this.resultMesssage = `申请提交成功`;

          break;
        case 4:
          this.resultMesssage = `您已在${this.companyName}中，请勿重复申请`;
          // this.$warning({
          //   icon: () => <a-icon type="exclamation-circle" style="color: #FAAD14;font-size: 21px" />,
          //   content: '您已在该企业中，请勿重复申请',
          //   okText: '我知道了',
          //   class: 'company-prompt-modal',
          //   onOk: () => {
          //     this.$emit('close')
          //     this.$router.push('/')
          //   },
          // })
          break;
        case 5:
          this.resultMesssage = "您已经在申请中，请勿重复申请";
          break;
      }
    },
    // 提交
    sendRequest() {
      let { isLoading, agreeProtocol } = this;
      if (isLoading) return;

      // if (!agreeProtocol) {
      //   showMsg('您没有同意服务协议和隐私政策！', 'warning')
      //   return
      // }

      const that = this;

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          const param = {
            ...this.form,
          };
          codeAuth(param).then((res) => {
            const next = this.$store.state.needNext;
            const route = this.$route;
            const redirect = decodeURIComponent(
              route.query.redirect || route.path
            );
            switch (res.result.authResult) {
              case 0:
                this.$success({
                  icon: () => (
                    <a-icon
                      type="check-circle"
                      style="color: #1890FF;font-size: 21px"
                    />
                  ),
                  content: "恭喜，您已成功加入企业",
                  class: "company-prompt-modal",
                  onOk: () => {
                    that.setTenant(res.result.tenantId);
                    this.$emit("close");
                  },
                });
                break;
              case 1:
                this.$success({
                  icon: () => (
                    <a-icon
                      type="check-circle"
                      style="color: #1890FF;font-size: 21px"
                    />
                  ),
                  content: `你加入${res.result.tenantName}的申请已提交，待管理员审批通过后会将审核结果发送至平台，请耐心等待。`,
                  okText: "我知道了",
                  class: "company-prompt-modal",
                  onOk: () => {
                    // this.$store.dispatch('refreshUserInfo')
                    this.$emit("close");
                    // next({ path: '/tenant' })
                  },
                });
                break;
              case 2:
                this.$error({
                  icon: () => (
                    <a-icon
                      type="exclamation-circle"
                      style="color: #FAAD14;font-size: 21px"
                    />
                  ),
                  content: "邀请码不存在，请检查后重新输入",
                  okText: "我知道了",
                  class: "company-prompt-modal",
                  onOk: () => {
                    this.$emit("close");
                  },
                });
                break;
              case 3:
                this.$error({
                  icon: () => (
                    <a-icon
                      type="exclamation-circle"
                      style="color: #FAAD14;font-size: 21px"
                    />
                  ),
                  content: "该邀请码已停止申请",
                  okText: "我知道了",
                  class: "company-prompt-modal",
                  onOk: () => {
                    this.$emit("close");
                  },
                });
                break;
              case 4:
                this.$message.warning("您已在该企业中，请勿重复申请");
                this.$router.push("/");
                // this.$warning({
                //   icon: () => <a-icon type="exclamation-circle" style="color: #FAAD14;font-size: 21px" />,
                //   content: '您已在该企业中，请勿重复申请',
                //   okText: '我知道了',
                //   class: 'company-prompt-modal',
                //   onOk: () => {
                //     this.$emit('close')
                //     this.$router.push('/')
                //   },
                // })
                break;
              case 5:
                this.$warning({
                  icon: () => (
                    <a-icon
                      type="exclamation-circle"
                      style="color: #FAAD14;font-size: 21px"
                    />
                  ),
                  content: "您已经在申请中，请勿重复申请",
                  okText: "我知道了",
                  class: "company-prompt-modal",
                  onOk: () => {
                    this.$emit("close");
                  },
                });
                break;
            }
            // this.$store.dispatch('refreshUserInfo', true)
            this.isLoading = false;
          });
        } else {
          return false;
        }
      });
    },

    // 取消
    handleCancel() {
      if (!this.resultPage) {
        this.$refs.ruleForm.resetFields();
      }
      Object.assign(this.$data, this.$options.data());
      console.log(this.modalData);
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.btn_down {
  width: 80%;
  margin: 10px auto;
}
</style>

<style>
.ant-modal-footer .btns-wrap {
  padding-left: 70%;
  display: flex;
  justify-content: flex-end;
}
</style>
